.icon{
	font-family: "FontAwesome";
	font-size: rem-calc(32);
}
.en{
	font-family: $en-font;
}
.copy{
	font-size: rem-calc(48);
	font-weight: 400;
	@include breakpoint(medium only){
		font-size: rem-calc(32);
	}
	@include breakpoint(small only){
		font-size: rem-calc(24);
	}
}
figure{
	@include breakpoint(small only){
		margin: 0;		
	};
}

.note{
	line-height: 1.2;
	font-size: rem-calc(14);
}
.credit{
	font-size: rem-calc(14);
}
.cell-top{
	display: table-cell;
	vertical-align: top;
}
.cell-middle{
	display: table-cell;
	vertical-align: middle;
	padding-left: $column-gutter / 2;
}
.cell-bottom{
	display: table-cell;
	vertical-align: bottom;
}
.share{
	>*{
		vertical-align: bottom;
	}
	@media screen and (max-width: 639px) {
		height: 60px;
	}
}
.underline{
	display: inline-block;
	background-image: -webkit-linear-gradient(transparent 0%, transparent 50%, $white 50%);
	background-image: -o-linear-gradient(transparent 0%, transparent 50%, $white 50%);
	background-image: linear-gradient(transparent 0%, transparent 50%, $white 50%);;
	background-position: 0 1.2em;
	background-repeat: repeat-x;
	background-size: 2px 2px;
}
.sub-note{
	display: inline-block;
	max-width: 400px;
	font-size: rem-calc(16);
	@include breakpoint(small only){
		max-width: 240px;
	}
}

.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
    left: 0;
}
.main-nav{
	&__lists{
	}
	&__list{
		a{
			&.current,
			&:hover{
				background-image: -webkit-linear-gradient(transparent 0%, transparent 50%, $main-nav-font-color 50%);
				background-image: -o-linear-gradient(transparent 0%, transparent 50%, $main-nav-font-color 50%);
				background-image: linear-gradient(transparent 0%, transparent 50%, $main-nav-font-color 50%);;
				background-position: 0 1.1em;
				background-repeat: repeat-x;
				background-size: 2px 2px;
			}
		}
	}
	&__menu-icon{
		position: absolute;
		top: 0; right: $column-gutter / 2;
		float: right;
		display: block;
		width: 48px;
		line-height: 48px;
		text-align: center;
		z-index: 100;
	}
	&__lists--dropdown{
		top: 50px;
		margin-left: -15px;
		padding: 10px 30px;
		li a{
			display: inline-block;
			&.current,
			&:hover{
				opacity: 0.5;
			}
		}
		&:before{
			left: auto;
			right: 1.1rem;
		}
		&:after{
			display: none;
		}
		&.open{
			left: 0;
		}
	}
	&__list--dropdown{
	}
}
.container{
	&__header{
		padding-top: $column-gutter/2;
	}
	&__side{
		$font-color: #17172c;
		$bg-color: $white;
		padding-top: 40px;
		padding-bottom: 10px;
		background-color: $bg-color;
		color: $font-color;
		.underline{
			background-image: -webkit-linear-gradient(transparent 0%, transparent 50%, $font-color 50%);
			background-image: -o-linear-gradient(transparent 0%, transparent 50%, $font-color 50%);
			background-image: linear-gradient(transparent 0%, transparent 50%, $font-color 50%);;
		}
	}
	&__footer{
		$bg-color: #17172c;
		padding-top: 40px;
		background-color: $bg-color;
	}
}

.account{
	&__title{
		display: inline-block;
		margin-right: 1em;
		font-style: italic;
		font-size: rem-calc(24);
		img{
			margin-right: 1em;
			vertical-align: -0.8em;
		}
	}
	&__icon{
		display: inline-block;
		width: 60px;
		text-align: center;
		font-family: "FontAwesome";
		font-size: rem-calc(32);
	}
}

$media-iPad: "(device-width: 768px) and (device-height: 1024px)";
$media-iPhone5: "(device-aspect-ratio: 40/71)";
.vh90{
	$factor: 0.9;
	height: 100vh * $factor;
	// iPad with portrait orientation.
	@media all and #{$media-iPad} and (orientation:portrait){
		& { height: 1024px * $factor; }
	}
	// iPad with landscape orientation.
	@media all and #{$media-iPad} and (device-height: 1024px) and (orientation:landscape){
		& { height: 768px * $factor; }
	}
	// iPhone 5
	@media screen and #{$media-iPhone5} {
		& { height: 500px * $factor; }
	}
}

.cover{
	//@extend .vh90;
	position: relative;
	min-height: 600px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	.row{
		//@extend .vh90;
		display: table;
		min-height: 600px;
		width: 100%;
	}
}
.contents{
	&__header{
		background-image: url(../images/bg_cover.jpg);
		background-position: center bottom;
		@include breakpoint(small only){
			background-image: url(../images/bg_cover_header.jpg);
		}
		.copy{
			padding-bottom: 40px;
		}
		.update{
			font-size: rem-calc(24);
			font-weight: 600;
		}
		.appstore-btn{
			position: absolute;
			right: 0;
			bottom: 30px;
		}
	}
	&__footer{
		background-image: url(../images/bg_cover_footer.jpg);
		.row{
			padding-bottom: 50px;
		}
		.appstore-btn{
			margin: 50px 0;
		}
	}
}

//intro
.intro-section{
	$bg-color: #17172c;
	background-color: $bg-color;
	&__image{
		img{
			position: relative; z-index: 1;
			margin-top: -20px;
			margin-bottom: 0px;
			@include breakpoint(medium up){
				margin-top: -200px;
				margin-bottom: -40px;
			}
		}
	}
	&__title{
		line-height: 1;
		margin: 0;
		//margin-top: 0.25em;
		padding: 0.5rem;
		background-color: #00ffb6;
		font-size: rem-calc(24);
		font-weight: 700;
		//color:
	}
	&__content{
		padding-top: 40px;
		padding-bottom: 10px;
		text-align: center;
		ul{
			overflow: hidden;
			margin: 0;
		}
		li{
			float: left;
			width: 33.33%;
		}
	}
}
.feature-icon{
	display: inline-block;
	width: 100%;
	line-height: 120px;
}

.feature-section{
	.columns{
		padding-top: 40px;
	}
	@include breakpoint(medium up){
		.columns{
			padding-top: 80px;
			min-height: 400px;
			margin: 0;
		}
	}
	.copy{
		position: relative; z-index: 1;
		margin-top: 1em;
		line-height: 1.1;
		@include breakpoint(medium only){
			font-size: rem-calc(28);
		}
	}
	&__content{
		// padding-bottom: 0px;
	}
	&__sub-content{
		min-height: 0 !important;
		margin-top: -40px !important;
		padding-top: 0 !important;
		padding-bottom: 80px;
	}
	&__copy--lang_ja{
		text-indent: -0.5em;
	}
	&__image-wrap{
		overflow: hidden;
		max-height: 320px;
		padding-top: 20px !important;
		padding-bottom: 40px;
	}
	&__image{
		img{
			width: 100%; height: auto;
		}
	}
	@include breakpoint(medium up){
		&__image-wrap{
			padding-bottom: 80px;
		}
	}
}
.icon-headline{
	&,
	&--vertical{
		font-size: rem-calc(24);
		font-weight: 600;
	}
	&{
		img{
			margin-right: 1em;
			vertical-align: middle;
		}
	}
	&--vertical{
		color: $white;
	}
}
.feature-interval{
	$bg-color-left: #17172c;
	$bg-color-right: #17172c;
	background-color: $bg-color-left;
	background-image: -webkit-linear-gradient(to right, transparent 0%, transparent 50%, $bg-color-right 50%);
	background-image: -o-linear-gradient(to right, transparent 0%, transparent 50%, $bg-color-right 50%);
	background-image: linear-gradient(to right, transparent 0%, transparent 50%, $bg-color-right 50%);
	.left{
		background-color: $bg-color-left;
	}
	.right{
		background-color: $bg-color-right;
	}
	figure{
		margin: 1em 40px;
	}
}
.feature-strobes{
	$bg-color-left: #17172c;
	$bg-color-right: #17172c;
	background-color: $bg-color-left;
	background-image: -webkit-linear-gradient(to right, transparent 0%, transparent 50%, $bg-color-right 50%);
	background-image: -o-linear-gradient(to right, transparent 0%, transparent 50%, $bg-color-right 50%);
	background-image: linear-gradient(to right, transparent 0%, transparent 50%, $bg-color-right 50%);
	.left{
		background-color: $bg-color-left;
	}
	.right{
		background-color: $bg-color-right;
	}
	figure{
		margin: 1em 40px;
	}

}
.feature-objects{
	$bg-color-left: #17172c;
	$bg-color-right: #17172c;
	background-color: $bg-color-left;
	background-image: -webkit-linear-gradient(to right, transparent 0%, transparent 50%, $bg-color-right 50%);
	background-image: -o-linear-gradient(to right, transparent 0%, transparent 50%, $bg-color-right 50%);
	background-image: linear-gradient(to right, transparent 0%, transparent 50%, $bg-color-right 50%);
	.left{
		background-color: $bg-color-left;
	}
	.right{
		background-color: $bg-color-right;
	}
	@include breakpoint(medium up){
		.copy{
			white-space: nowrap;
		}
	}
	figure{
		margin: 1em 40px;
	}
}
.feature-watch{
	$bg-color-left: #0a0c38;
	$bg-color-right: #0a0c38;
	background-color: $bg-color-left;
	.left{
		background-color: $bg-color-left;
	}
	.right{
		background-color: $bg-color-right;
	}
	figure{
		margin: 10px;
	}
	figcaption{
		margin: 10px 0;
		text-align: center;
	}
	.icon-headline{
		text-align: center;
		text-decoration: underline;
	}
	.copy{
		text-indent: 0;
		white-space: nowrap;
		text-align: center;
		line-height: 1.25;
		font-size: rem-calc(24);
		@include breakpoint(large up){
			font-size: rem-calc(36);
		}
	}
	.sub-copy{
		white-space: normal;
	}
}

.instagram-section{
    overflow: hidden;
    overflow-x: auto;
    #instafeed{
		white-space: nowrap;
    }
    img{
		width: 306px;
		height: auto;
    }
}
.review-section{
	$bg-color:#17172c;
	$link-color: #00ffb6;
	padding-top: 80px;
	padding-bottom: 80px;
	background-color: $bg-color;
	.copy{
		margin: 20px auto 80px;
		text-indent: -0.5em;
		font-size: rem-calc(29);
	}
	&__title{
		margin-bottom: 40px;
		font-weight: bold;
	}
	.media-list{
		text-align: justify;
		&__title,
		&__entry{
			display: inline;
			font-size: rem-calc(14);
			font-weight: 200;
		}
		&__title a{
			color: $white;
		}
		&__title:after{
			content:": ";
		}
		&__entry{

			margin-right: 0.25em;
		}
		@include breakpoint(medium up){
			&__title,
			&__entry{
				font-size: rem-calc(24);
			}
		}
	}
	a{
		color: $link-color;
		&:hover{
			opacity: 0.7;
		}
	}
}
