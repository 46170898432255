.main-nav{
	&{
		position: relative;
	}
	&__lists{
		text-align: $main-nav-align;
	}
	&__list{
		display: inline-block;
		margin-left: 0.5em;
		margin-right: 0.5em;
		font-family: $main-nav-font-family;
		font-style: $main-nav-font-style;
		font-weight: $main-nav-font-weight;
		font-size: $main-nav-font-size;
	}
	a{
		text-decoration: none;
		color: $main-nav-font-color;
	}
}

.f-dropdown {
    display: none;
    left: -9999px;
    list-style: none;
    margin-left: 0;
    position: absolute;
    background: #000;
    border: solid 0 #000;
    font-size: 1.125rem;
    height: auto;
    max-height: none;
    width: 100%;
    z-index: 89;
    margin-top: 2px;
    max-width: 260px;
		&.open {
		    display: block;
		}
		li {
		    cursor: pointer;
		    font-size: 1.125rem;
		    line-height: 2.75rem;
		    margin: 0;
		}
		li a {
		    display: block;
		    padding: 0.5rem;
				font-weight: bold;
		    color: #fff;
		}
}
