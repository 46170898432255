$en-font: "Avenir Next", Avenir, Helvetica, sans-serif;

/*
* Noto Sans Japanese (japanese) http://www.google.com/fonts/earlyaccess
 */
@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 100;
  src: url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-Thin.woff2) format('woff2'),
       url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-Thin.woff) format('woff'),
       url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-Thin.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 200;
  src: url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-Light.woff2) format('woff2'),
       url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-Light.woff) format('woff'),
       url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-Light.otf) format('opentype');
}
// @font-face {
//    font-family: 'Noto Sans Japanese';
//    font-style: normal;
//    font-weight: 300;
//    src: url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-DemiLight.woff2) format('woff2'),
//         url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-DemiLight.woff) format('woff'),
//         url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-DemiLight.otf) format('opentype');
// }
@font-face {
   font-family: 'Noto Sans Japanese';
   font-style: normal;
   font-weight: 400;
   src: url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-Regular.woff2) format('woff2'),
        url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-Regular.woff) format('woff'),
        url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-Regular.otf) format('opentype');
 }
// @font-face {
//    font-family: 'Noto Sans Japanese';
//    font-style: normal;
//    font-weight: 500;
//    src: url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-Medium.woff2) format('woff2'),
//         url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-Medium.woff) format('woff'),
//         url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-Medium.otf) format('opentype');
//  }
// @font-face {
//    font-family: 'Noto Sans Japanese';
//    font-style: normal;
//    font-weight: 700;
//    src: url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-Bold.woff2) format('woff2'),
//         url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-Bold.woff) format('woff'),
//         url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-Bold.otf) format('opentype');
//  }
// @font-face {
//    font-family: 'Noto Sans Japanese';
//    font-style: normal;
//    font-weight: 900;
//    src: url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-Black.woff2) format('woff2'),
//         url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-Black.woff) format('woff'),
//         url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-Black.otf) format('opentype');
//  }
